import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { bp } from "../styles/colors";
import SectionDescription from "./sectionDescription";
import ProjectCard from "./projectCard";
import ProjectPopup from "../components/projectPopup";
import SectionTitle from "./sectionTitle";

const WorkSection = () => {
    const [popOpen, setPopOpen] = useState(null);

    if (typeof window !== "undefined" && window.document) {
        if (popOpen !== null) {
            document.body.style.overflow = "hidden";
            document.body.style.width = "100%";
        } else {
            document.body.style.overflow = "unset";
        }
    }

    const projectData = useStaticQuery(graphql`
        query ProjectTest {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "project" } } }
                sort: { order: DESC, fields: frontmatter___smallDesc }
            ) {
                nodes {
                    frontmatter {
                        description
                        title
                        listElements {
                            listElement
                        }
                        location
                        medTitle
                        projectDesv
                        smallDesc
                        smallTitle
                        bigImg {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        smallImg2 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                        smallImg3 {
                            childImageSharp {
                                fluid(maxWidth: 500, maxHeight: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                                id
                            }
                        }
                    }
                    id
                }
            }
        }
    `);

    const projectList = projectData.allMarkdownRemark.nodes;

    const getProjectImages = (node) => {
        const data = node.frontmatter;
        const images = [];

        if (data.bigImg) {
            images.push(data.bigImg);
        }

        if (data.smallImg2) {
            images.push(data.smallImg2);
        }

        if (data.smallImg3) {
            images.push(data.smallImg3);
        }

        return images;
    };

    return (
        <SectionWrapper>
            <BreakpointContainer>
                <SectionContainer>
                    <SectionTitle title="Projektit" />
                    <SectionDescription
                        smallTitle="Tuoreimmat referenssit"
                        mediumTitle="Valmistuneet projektimme"
                        blackText="Olemme ylpeitä jäljestämme ja kuluneiden vuosien aikana valmistuneista niin isoista kuin pienistäkin urakoista. Lahden lisäksi olemme toteuttaneet töitä ympäri Etelä-Suomea muun muassa Orimattilassa, Porvoossa ja Hollolassa."
                        buttonDesc="Lisää töitä"
                        buttonTo="/referenssit"
                        internalButton={true}
                        style={{ marginBottom: "10rem" }}
                    ></SectionDescription>
                    <ProjectContainer>
                        {projectList
                            .map((n, index) => (
                                <ProjectCard
                                    key={index}
                                    id={n.id}
                                    onClick={(e) => {
                                        setPopOpen(n);
                                    }}
                                    fluidImg={
                                        n.frontmatter.bigImg.childImageSharp
                                            .fluid
                                    }
                                    title={n.frontmatter.title}
                                    projectDesv={n.frontmatter.projectDesv}
                                ></ProjectCard>
                            ))
                            .slice(0, 2)}
                    </ProjectContainer>
                </SectionContainer>
            </BreakpointContainer>
            {popOpen && (
                <ProjectPopup
                    data-type-="popupElem"
                    smallTitle={popOpen.frontmatter.smallTitle}
                    medTitle={popOpen.frontmatter.medTitle}
                    smallDesc={popOpen.frontmatter.smallDesc}
                    location={popOpen.frontmatter.location}
                    listElements={[...popOpen.frontmatter.listElements]}
                    description={popOpen.frontmatter.description}
                    images={getProjectImages(popOpen)}
                    onClick={() => setPopOpen(null)}
                    id={popOpen.id}
                />
            )}
        </SectionWrapper>
    );
};

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 5rem 5rem 5rem;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 3rem 1rem 3rem 1rem;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0rem;
    width: 100%;
`;

const ProjectContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    flex-grow: 1;

    @media screen and (max-width: 1366px) {
        margin-top: 3rem;
    }

    @media screen and (max-width: ${bp.mobile}) {
        margin-top: 2rem;
    }
`;

const BreakpointContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${bp.large};
`;

export default WorkSection;
