import React from 'react'
import styled from 'styled-components'
import { bp } from '../styles/colors'
import { MediumTitle, SmallTitle } from '../styles/fonts'
import BasicButton from './basicButton'

const WelcomeSection = (props) => {
    return (
        <WelcomeContainer>
            <SmallTitle style={{margin: '5rem 0 1rem 0', textAlign: 'center'}}>{props.smallTitle}</SmallTitle>
            <WelcomeMediumTitle>{props.bigTitle} <br></br> -periaatteella </WelcomeMediumTitle>
            <ButtonsContainer>
                <BasicButton theme='' to={props.firstButtonTo} internal={false} arrow='right'>{props.firstButtonText}</BasicButton>
                <BasicButton theme='dark' to={props.secondButtonTo} internal={true} arrow='right'>{props.secondButtonText}</BasicButton>
            </ButtonsContainer>
     
        </WelcomeContainer>
    )
}



const WelcomeContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 12rem 0 0rem 0;
  z-index: 1;

  @media screen and (max-width: ${bp.mobile}) {
        margin: 7rem 0 3rem 0;
    }
`

const WelcomeMediumTitle = styled(MediumTitle)`
    text-align: center;
    margin: 0 0 2rem 0;
    font-size: 3.5rem;

  @media screen and (max-width: ${bp.mobile}) {
    font-size: 2.5rem;
        margin: 0 0 2rem 0;
    }
`

const ButtonsContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 25rem;
   // z-index: -1;

    @media screen and (max-width: ${bp.mobile}) {
        max-width: unset;
        flex-direction: column;
        justify-content: center;
        gap: 0rem;
        align-items: center;
    }

`

export default WelcomeSection