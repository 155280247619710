import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { bp } from '../styles/colors'
import scrollTo from 'gatsby-plugin-smoothscroll'


const BasicButton = (props) => {
    return (
        <ButtonContainer theme={props.theme} to={props.to} onClick={props.onClick}>
            {props.internal 
                ? <ButtonLink to={props.to}/>
                : <ButtonA onClick={() => scrollTo(props.to)}/>
            }
            {props.children}
        </ButtonContainer>
    )
}

const ButtonContainer = styled.button`
    font-family: 'Arial';
    src: local('Arial'), url(/src/fonts/Arial.ttf) format('truetype');
    cursor: pointer;
    background-color: ${props => props.theme === 'dark' ? 'transparent' : 'black'};
    position: relative;
    max-width: fit-content;
    block-size: fit-content;
    display:block;
    border-radius: 2rem;
    color: ${props => props.theme === 'dark' ? 'black' : 'white'};
    border: 4px solid ${props => props.theme === 'dark' ? 'black' : 'black'};
    padding: 0.6rem 1.1rem;
    margin: 0.6rem 0 0.6rem 0;
    white-space: nowrap;
    z-index: 6;
    transition: 0.2s all;
    font-size: 1rem;
 
    

    &:hover {
        color: ${props => props.theme === 'dark' ? 'white' : 'black'};
        background-color: ${props => props.theme === 'dark' ? 'black' : 'transparent'};
        cursor: pointer;
    }

    @media screen and (max-width: ${bp.mobile}) {

   
        -webkit-tap-highlight-color: transparent;

    &:hover {
        color: ${props => props.theme === 'dark' ? 'black' : 'white'};
        background-color: ${props => props.theme === 'dark' ? 'transparent' : 'black'};
        cursor: pointer;
    }
    }
`
const ButtonLink = styled(Link)`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    outline: none;
    box-shadow: none;
    
`
const ButtonA = styled.a`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
`

export default BasicButton