import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { bp } from "../styles/colors";
import { colors } from "../styles/colors";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

//Random numerot, joilla valitaan mobiilin kuvat
let arr = [];
while (arr.length < 6) {
  let r = Math.floor(Math.random() * 36);
  if (arr.indexOf(r) === -1) arr.push(r);
}

export const ScrollAnimation = () => {
  return <ImagesContainer></ImagesContainer>;
};

const PhotoCarousel = () => {
  const [currentPrecent, setCurrentPercent] = useState(null);
  const [offSet, setOffset] = useState(0);
  const [animDuration, setDuration] = useState(0);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 1], [0, 5000]);

  useEffect(
    () =>
      yRange.onChange((v) => {
        setDuration(0);
        setCurrentPercent(yRange.current);
      }),
    [yRange]
  );

  const data = useStaticQuery(graphql`
    query CarouselImages {
      carousel: allFile(filter: { relativeDirectory: { eq: "square" } }) {
        nodes {
          id
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const jumpF = () => {
    setDuration(0.4);
    if (offSet < 8000) setOffset(offSet + 2000);
  };
  const jumpB = () => {
    setDuration(0.4);
    if (offSet > -10000) setOffset(offSet - 2000);
  };

  return (
    <CarouselWrapper>
     
      <ImagesContainer
        animate={{
          translateX: -((currentPrecent + offSet) / 2),
        }}
        transition={{ bounce: 0, duration: animDuration }}
        style={{}}
      >
        {data.carousel.nodes.map((image) => (
          <ImageBox
            style={{ width: "300px" }}
            key={image.id}
            fluid={image.childImageSharp.fluid}
          />
        ))}
      </ImagesContainer>

        <FounderImagesContainer>
          <ImageContainer className="item1" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[0]].childImageSharp.fluid}></ImageContainer>
          <ImageContainer className="item2" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[1]].childImageSharp.fluid}></ImageContainer>
          <ImageContainer className="item3" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[2]].childImageSharp.fluid}></ImageContainer>
          <ImageContainer className="item4" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[3]].childImageSharp.fluid}></ImageContainer>
          <ImageContainer className="item5" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[4]].childImageSharp.fluid}></ImageContainer>
          <ImageContainer className="item6" style={{ width: "100%" }} fluid={data.carousel.nodes[arr[5]].childImageSharp.fluid}></ImageContainer>
        </FounderImagesContainer>


      <ButtonContainer>
        <Button onClick={jumpB}>{offSet > -10000 && <FaArrowLeft />}</Button>
        <Button onClick={jumpF}>{offSet < 8000 && <FaArrowRight />}</Button>
      </ButtonContainer>
    </CarouselWrapper>
  );
};

const FounderImagesContainer = styled.div`

display: none;

@media screen and (max-width: ${bp.mobile}) {
  
  width: 100%;
	display: grid;
	grid-template-columns: [line1] 1fr [line2] 1fr [line3] 1fr  [end];
  	grid-template-rows: [row1] 1fr [row2] 1fr [row3] 1fr [row4] 1fr [row-end];
    gap: 15px;


    .item1{
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;

    }

    .item2{
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
      
    }

    .item3 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;

    }

    .item4 {
      grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;

    }

    .item5 {
      grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    .item6 {
      grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 5;
    }


  }
`;

const ImageContainer = styled(Img)`
  
`;


const ImageBox = styled(Img)`
  width: 300px;

  @media screen and (max-width: ${bp.mobile}) {
    max-width: 200px;
  }
`;


const CarouselWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  margin: 6rem 0 0 0 ;

  @media screen and (max-width: ${bp.mobile}) {
    width: unset;
    margin: 0 1rem 3rem 1rem;
  }

  ::before,
  ::after {
  }
`;

const ImagesContainer = styled(motion.div)`
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
  transform: translateX(-10rem);

  @media screen and (max-width: ${bp.mobile}) {
    gap: 1rem;
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10rem;
  margin: 2rem 0 0 0;

  @media screen and (max-width: ${bp.mobile}) {
    display: none;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  width: 3rem;
  height: 3rem;
  //border: 4px solid ${colors.black};
  background-color: ${colors.grey6};
  cursor: pointer;

  * {
    color: ${colors.grey2};
    height: 1rem;
    width: 1rem;
    transition: 0.1s;
  }

  &:hover {
    * {
      color: ${colors.grey4};
    }
  }
`;

export default PhotoCarousel;
