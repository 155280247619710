import React from "react";
import styled from "styled-components";
import { BigTitle, BlackText, MediumTitle, SmallTitle } from "../styles/fonts";
import { bp, colors } from "../styles/colors";
import { StaticImage } from 'gatsby-plugin-image'
import { InView } from "react-intersection-observer";

const anttiStory =
  "Intohimoisesti rakentamiseen suhtautuva Antti hallitsee kokonaiskuvan kaikista talon rakentamisen tehtävistä. Uudiskohteet, huoneistoremontit ja pihatyöt luonnistuvat vankalla ammattitaidolla. Erityisesti mämmi- ja kurahommat kuten valutyöt, muuraukset ja laatoitukset sekä tasoitukset ja rappaukset ovat Antin erikoisalaa. Antilla on VTT:n vedeneristyssertifikaatti.";
const mikkoStory =
  "Työstään inspiroitunut Mikko tekee laadukkaasti kaikki rakennustyöt rakentamisen eri vaiheissa. Monilla työmailla kypsynyt Mikko on elementissään vasara, naulapyssy tai ruuvinväännin kädessään. Kaikki työt perustuksista rungon pystytykseen, levytyksistä panelointeihin ja viimeistelytyöt listoituksineen kuuluvat Mikon ydinosaamiseen.";

const AboutSection = (props) => {
  

  return (
    <InView threshold={0.18}>
      {({ ref, inView }) => (
        <SectionWrapper
          ref={ref}
          style={{
            transition: "0.5s",
            opacity: inView ? "100%" : "0%",
          }}
        >
          <AboutContainer>
            <SmallTitle style={{ margin: "0rem 0 1rem 0rem", color: colors.grey3 }}>
              Perustajina Ylitalon veljekset
            </SmallTitle>
            <MediumTitle style={{ margin: "0rem 0 5rem 0rem", color: "white" }}>
              Työtä vankalla kokemuksella
            </MediumTitle>
            <FoundersWrapper>
              <FounderContainer wrapAlign="left">

                <FounderImagesContainer wrapAlign="left">
		  			<ImageContainer height={400} width={200} className='item1'> <StaticImage src="../images/founders/Ylitalon-hnklökuvat-3.jpg" alt="Antti" layout="constrained" /> </ImageContainer>
					<ImageContainer height={300} width={300} className='item2'> <StaticImage src="../images/founders/Ylitalon-hnklökuvat-2.jpg" alt="Antti" layout="constrained" /></ImageContainer>
          <MediumTitle className='name1' style={{ margin: "", color: "white" }}>Antti Ylitalo</MediumTitle>
                </FounderImagesContainer>

                <FounderTextContainer>
                  <BigTitle
                    style={{ margin: "0rem 0 0rem 0rem", color: colors.grey3}}
                  >
                    "
                  </BigTitle>
                  <BlackText style={{ color: "white", marginTop: "-5rem" }}>
                    {anttiStory}
                  </BlackText>
                </FounderTextContainer>
              </FounderContainer>
              <FounderContainer reverse="true">
                <FounderTextContainer>
                  <BigTitle
                    style={{ margin: "0rem 0 0rem 0rem", color: colors.grey3}}
                  >
                    "
                  </BigTitle>
                  <BlackText style={{ color: "white", marginTop: "-5rem" }}>
                    {mikkoStory}
                  </BlackText>
                </FounderTextContainer>

                <FounderImagesContainer2 wrapAlign="">
					<ImageContainer height={300} width={300}  className='item3'> <StaticImage src="../images/founders/Ylitalon-hnklökuvat-1.jpg" alt="Antti" layout="constrained" /> </ImageContainer>
					<ImageContainer height={400} width={200}  className='item4'> <StaticImage src="../images/founders/Ylitalon-hnklökuvat-4.jpg" alt="Antti" layout="constrained" /> </ImageContainer>
          <MediumTitle className='name2' style={{ margin: "", color: "white" }}>Mikko Ylitalo</MediumTitle>
                </FounderImagesContainer2>

              </FounderContainer>
            </FoundersWrapper>
          </AboutContainer>
        </SectionWrapper>
      )}
    </InView>
  );
};

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  overflow: hidden;
  transition: 0.15s all;
  width: 100%;
`;

const AboutContainer = styled.section`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 10rem 5rem 10rem 5rem;
  overflow-x: hidden;
  overflow: hidden;
  max-width: ${bp.large};

  @media screen and (max-width: ${bp.mobile}) {
    margin: 5rem 1rem 3rem 1rem;
  }
`;

const FoundersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10rem;
  overflow: hidden;

  @media screen and (max-width: ${bp.mobile}) {
    gap: 5rem;
  }
`;

const FounderContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${(props) => (props.reverse === "true" ? "wrap-reverse" : "wrap")};
  gap: 4rem;
  align-self: flex-end;
  justify-self: flex-end;

  @media screen and (max-width: ${bp.large}) {
    align-items: ${(props) =>
      props.wrapAlign === "left" ? "flex-start" : "flex-end"};
    justify-content: ${(props) =>
      props.wrapAlign === "left" ? "flex-start" : "flex-end"};
  }

  @media screen and (max-width: ${bp.mobile}) {
    align-items: left;
    justify-content: flex-start;
    margin: 0 0 3rem 0;
  }
`;

const FounderImagesContainer2 = styled.div`
	display: grid;
	grid-template-columns: [line1] 300px [line2] 200px [end];
  	grid-template-rows: [row1] 300px [row2] 100px [row-end];
    gap: 0px 30px;

    @media screen and (max-width: ${bp.mobile}) {
    width: 100%;
    grid-template-columns: [line1] 3fr [line2] 2fr [end];
  	grid-template-rows: [row1] 3fr [row2] 1fr [row-end];
    gap: 0px 15px;
    }

    .item3{
      grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;

    }

    .item4{
      grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3; 
    
    }

    .name2 {
      align-self: flex-end;
      justify-self: flex-start;
      margin-bottom: -0.7rem;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;

   @media screen and (max-width: ${bp.mobile}) {
    margin-bottom: -0.5rem;
    }
    }
`;

const FounderImagesContainer = styled.div`
	display: grid;
	grid-template-columns: [line1] 200px [line2] 300px [end];
  	grid-template-rows: [row1] 300px [row2] 100px [row-end];
    gap: 0px 30px;

    @media screen and (max-width: ${bp.mobile}) {
    max-width: 100%;
    width: 100%;
    grid-template-columns: [line1] 2fr [line2] 3fr [end];
  	grid-template-rows: [row1] 3fr [row2] 1fr [row-end];
    gap: 0px 15px;
    }

    .item1{
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;

    }

    .item2{
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      
    }

    .name1 {
      align-self: flex-end;
      justify-self: flex-end;
      margin-bottom: -0.7rem;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    @media screen and (max-width: ${bp.mobile}) {
    margin-bottom: -0.5rem;
    justify-self: flex-end;
    }
    }
`;

const ImageContainer = styled.div`

height: ${props => props.height}px;
    width: ${props => props.width}px;

    @media screen and (max-width: ${bp.mobile}) {
        height: 100%;
        width: 100%;
    }
`;

const FounderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  justify-content: space-around;
  color: white;

  @media screen and (max-width: 1090px) {
    flex-basis: 50%;
  }

  @media screen and (max-width: ${bp.mobile}) {
    flex-basis: 100%;
  }
`;

export default AboutSection;
