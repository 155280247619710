import * as React from "react";
import Layout from "../components/layout";
import WelcomeSection from "../components/welcomeSection";
import PhotoCarousel from "../components/photoCarousel";
import AboutSection from "../components/aboutSection";
import ServiceSection from "../components/serviceSection";
import WorkSection from "../components/workSection";
import MediaSection from "../components/mediaSection";
import Seo from "../components/Seo";
import { graphql, useStaticQuery } from "gatsby";


const IndexPage = () => {



  const indexSeoPic = useStaticQuery(graphql`
  query indexSeoPic {
    image: file(relativePath: { eq: "square/Ylitalo-15.jpg" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);

  return (
    <Layout>
      <Seo
        title="Ylitalon rakennuspalvelu Oy"
        description="Rakennuspalveluita yli 20 vuoden kokemuksella avaimet käteen -periaatteella. Toteutamme monipuolisesti rakennusurakoita Lahden alueella."
        keywords={["talon rakentaminen", "rakentaminen", "rakennuspalvelut", "sähkötyöt", "lvi", "arkkitehtisuunnittelu", "uudisrakentaminen", "työkalujen vuokraus", "työkaluvuokraus", "Lahti", "Lahden talousalue", "Porvoo", "Orimattila", "Hollola"]}
        url="https://ylitalon.netlify.app"
        image={"https://ylitalon.netlify.app" + indexSeoPic.image.publicURL}
      />
      <WelcomeSection
        smallTitle="Rakentamista yli 20 vuoden kokemuksella"
        bigTitle="Avaimet käteen"
        firstButtonText="Kysy tarjous"
        firstButtonTo="#contactSection"
        secondButtonText="Palvelumme"
        secondButtonTo="/palvelut"
        hugeQuote=""
      ></WelcomeSection>
      <PhotoCarousel></PhotoCarousel>
      <ServiceSection title="Palvelut"></ServiceSection>
      <AboutSection></AboutSection>
      <WorkSection></WorkSection>
      <MediaSection></MediaSection>
    </Layout>
  );
};

export default IndexPage;
