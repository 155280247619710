import React from 'react'
import styled from 'styled-components'
import { bp } from '../styles/colors'
import SectionDescription from '../components/sectionDescription'
import SectionTitle from './sectionTitle'
import CuratorWidget from './CuratorWidget'


const MediaSection = () => {

    return (
    <SectionWrapper>
    <BreakpointContainer>
    <SectionTitle
            title = "Uutiset"
        />
    <SectionContainer>
    <SectionDescription
                smallTitle="Seuraa meitä "
                mediumTitle="Pysy ajantasalla"
                blackText="Julkaisemme aktiivisesti Facebookissa kuvia ja tarinoita töistämme. Tykkää Ylitalon rakennuspalvelun sivusta, niin pääset seuraamaan työmaiden arkea sekä valmistuvia projekteja."
                buttonDesc="Tykkää meistä"
                buttonTo="https://www.facebook.com/ylitalon"
                internalButton={true}
            />
        <Feed>
 
        <CuratorWidget feedId="546ae5ee-ca03-49a2-aa60-54fe9c5e54af"/>
        <WhiteDiv></WhiteDiv>

        </Feed>
       </SectionContainer>   
       </BreakpointContainer>
       </SectionWrapper> 
    )
}

const WhiteDiv = styled.div`
    width: 100%;
    height: 1rem;
    background-color: black;
    margin-top: -1.3rem;
    z-index: 10000000;
`

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem 5rem 9.5rem 5rem;

    @media screen and (max-width: ${bp.mobile}) {
       margin: 5rem 1rem 0rem 1rem;
    }    
`

const SectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${bp.mobile}) {
       justify-content: flex-start;
       flex-direction: column;
    }
`
const Feed = styled.div`
    width: 50%;
    height: fit-content;
    margin-bottom: 5rem;
    //box-shadow:      0px 0px 10px rgba(0, 0, 0, 0.05),
                    //0px 0px 80px rgba(0, 0, 0, 0.1);

 @media screen and (max-width: ${bp.mobile}) {
        margin-top: 3rem;
        width: 100%;
    }
`

const BreakpointContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: ${bp.large};

`

export default MediaSection